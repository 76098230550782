import { useState } from 'react';

import {
	Badge,
	Button,
	CircularProgress,
	cn,
	CrossIcon,
	CurrentStepIcon,
	IconButton,
	InputWithLabel,
	Modal,
	SaveIcon,
	TextInput,
	useModal,
} from 'crunch-components';

import { Controller, useForm } from 'react-hook-form';
import { useMutation, useQueryClient, UseQueryOptions } from 'react-query';

import {
	CREATE_OBJECTIVE,
	GET_PRODUCT_FILTERS,
	UPDATE_OBJECTIVE,
} from 'api/objectives-v2';
import { FilterMap } from 'components/Filter/FullFilterMenu.types';
import Title from 'components/Title/Title';
import useChannelQuery from 'hooks/channels/useChannelQuery';
import useChannelStore from 'hooks/channels/useChannelStore';
import constructChannelQueryKey from 'utils/channelUtils';
import {
	ObjectivesGroupBasicDataType,
	ProductFiltersDataType,
} from '../types/objectives';
import { ModalType } from '../types/strategies';
import { Products } from './Products';
import { ALL_PRODUCT_FILTERS_KEY } from './queries';

type ObjectiveScopeModalProps = {
	objective: ObjectivesGroupBasicDataType;
	type: ModalType;
	/** not used yet */
	onSuccess?: () => Promise<void>;
	/** not used yet */
	onMutate?: () => Promise<void>;
};

export function getExistingFilters(
	objectiveFilters?: ObjectivesGroupBasicDataType['filters'],
	productFilters?: ProductFiltersDataType,
): FilterMap {
	if (objectiveFilters === undefined || productFilters === undefined) {
		return new Map() as FilterMap;
	}

	return objectiveFilters.reduce((acc, cur) => {
		const pf = productFilters?.find((f) => f.id === cur.filter_id);
		if (pf !== undefined) {
			acc.set(pf.id, {
				id: pf.id,
				name: pf.name,
				value: cur.values,
				isDefault: false,
				filterMeta: pf,
			});
		}
		return acc;
	}, new Map() as FilterMap);
}

export const ObjectiveScopeModal = ({
	type: scenario,
	objective,
	onSuccess,
	onMutate,
}: ObjectiveScopeModalProps) => {
	const initialFormValues = {
		name: objective.name,
	};
	const isLoading = false;

	const productFilterQuery = (extra?: UseQueryOptions) =>
		useChannelQuery<
			ProductFiltersDataType,
			unknown,
			ProductFiltersDataType
		>(ALL_PRODUCT_FILTERS_KEY, GET_PRODUCT_FILTERS, {
			staleTime: 30 * 60 * 1000,
			...extra,
		});

	const queryClient = useQueryClient();
	const { activeChannel } = useChannelStore();
	const populatedProductFilterKey = constructChannelQueryKey(
		activeChannel,
		ALL_PRODUCT_FILTERS_KEY,
	);
	const [filters, setFilters] = useState(
		getExistingFilters(
			objective.filters,
			queryClient.getQueryData(populatedProductFilterKey),
		),
	);

	productFilterQuery({
		onSuccess: (pf: any) => {
			setFilters(getExistingFilters(objective.filters, pf));
		},
	});

	const { close } = useModal();

	const {
		control,
		formState,
		handleSubmit: doFormSubmission,
	} = useForm({ defaultValues: initialFormValues });

	/*  FORM SUBMISSION */
	// we have separate mutations for create and update

	const { isLoading: isCreateObjectiveLoading, mutate: createObjective } =
		useMutation(CREATE_OBJECTIVE, {
			onMutate: async () => {
				await onMutate?.();
			},
			onSuccess: async () => {
				await onSuccess?.();
				if (close !== undefined) {
					close();
				}
			},
		});

	const { isLoading: isUpdateObjectiveLoading, mutate: updateObjective } =
		useMutation(UPDATE_OBJECTIVE, {
			onMutate: async () => {
				await onMutate?.();
			},
			onSuccess: async () => {
				await onSuccess?.();
				if (close !== undefined) {
					close();
				}
			},
		});

	const handleSubmit = async (data: typeof initialFormValues) => {
		const payload = {
			...data,
			filters: Array.from(filters.values()).map((filterValue) => ({
				filter_id: filterValue.id,
				values: filterValue.value as any, // TODO: we should really type this correctly!
			})),
		};

		return scenario === 'create'
			? createObjective({ ...payload, strategy_objectives: [] })
			: updateObjective({
					...payload,
					id: objective.id,
					strategy_objectives: objective.strategy_objectives,
				});
	};

	const isSaving = isCreateObjectiveLoading || isUpdateObjectiveLoading;

	let modalContent = (
		<div className="flex max-h-[80vh] flex-row">
			<div className="flex w-[200px] flex-shrink-0 flex-col items-start justify-center rounded-md bg-ca-silver px-5">
				{scenario === 'create' ? (
					<Badge variant="success" className="ml-2 font-normal">
						Create
					</Badge>
				) : (
					<Badge variant="neutral" className="ml-2 font-normal">
						Edit
					</Badge>
				)}
				<p className="mb-4 ml-2 text-sm font-bold text-black">
					Objective group
				</p>
				<div
					className={cn(
						'flex items-center py-2 text-sm text-ca-purple',
					)}
				>
					<span className="flex h-3 items-center justify-center">
						<CurrentStepIcon className="m-1 h-4 w-auto" />
					</span>
					<span className="ml-2">Name & scope</span>
				</div>
			</div>
			<form
				onSubmit={doFormSubmission(handleSubmit)}
				className="flex max-h-[100vh] min-h-[320px] w-max min-w-[640px] flex-col pl-6"
			>
				{scenario === 'create' ? (
					<div className="mb-7 mt-2">
						<Title
							font="sans"
							color="forest"
							size="h2-like"
							as="h2"
						>
							New objective group
						</Title>

						<p className="text-sm text-ca-gray-500">
							Give this group a name and determine the product
							scope.
						</p>
					</div>
				) : (
					<div className="mb-7 mt-2">
						<p className="text-2xl font-bold text-black">
							Edit objective group
						</p>
						<p className="text-sm text-ca-gray-500">
							Edit the name and/or update the products scope.
						</p>
					</div>
				)}
				<InputWithLabel
					label="Name"
					htmlFor="name"
					labelClassName="w-32 mb-2 md:mb-0"
				>
					<Controller
						name="name"
						control={control}
						rules={{ required: 'Required field' }}
						render={({ field }) => {
							return (
								<TextInput
									type="text"
									id="title"
									className="w-full sm:w-64"
									value={field.value}
									onChange={(val: any) => field.onChange(val)}
									error={formState?.errors?.name?.message}
								/>
							);
						}}
					/>
				</InputWithLabel>
				<Products
					objective={objective}
					setFilters={setFilters}
					filters={filters}
					filterQuery={productFilterQuery}
				/>
				<div className="mt-6 flex">
					<Button
						variant="link"
						className="font-medium"
						onClick={() => {
							if (close !== undefined) {
								close();
							}
						}}
					>
						Close
					</Button>
					<Button
						className="ml-auto flex flex-row items-center gap-3 whitespace-nowrap"
						variant="primary"
						type="submit"
						disabled={isSaving}
					>
						{isSaving ? (
							'Saving...'
						) : (
							<span className="flex flex-row gap-2">
								<SaveIcon className="ml-1 h-3.5 w-auto" /> Save
							</span>
						)}
					</Button>
				</div>
			</form>
		</div>
	);

	if (isLoading) {
		modalContent = (
			<div className="flex h-[280px] max-h-full w-[600px] max-w-full flex-col items-center justify-center">
				<CircularProgress />
			</div>
		);
	}

	return (
		<Modal.Root className="w-full max-w-[1500px] overflow-hidden rounded-2xl">
			<Modal.Content className="relative bg-ca-gray-100 p-2.5">
				<span className="absolute right-5 top-5 flex">
					<IconButton
						className="h-auto w-4 text-ca-gray-500"
						tooltip="Close"
						icon={CrossIcon}
						onClick={() => {
							if (close !== undefined) {
								close();
							}
						}}
					/>
				</span>
				{modalContent}
			</Modal.Content>
		</Modal.Root>
	);
};
