import {
	Button,
	CircularProgress,
	cn,
	Dropdown,
	IconButton,
	PlusIcon,
	Text,
	TrashIcon,
} from 'crunch-components';

import {
	Control,
	Controller,
	useFieldArray,
	UseFormSetValue,
	UseFormWatch,
} from 'react-hook-form';

import { components } from '../../../types/backend-api';
import ConditionLabel from './ConditionLabel';
import ConditionValueInput from './ConditionValueInput';

export enum OPERATOR {
	IS_IN = 'is in',
	IS_NOT_IN = 'is not in',
	CONTAINS = 'contains',
	IS_LESS_THAN = 'is less than',
	IS_GREATER_THAN = 'is greater than',
	IS_BEFORE = 'is before',
	IS_AFTER = 'is after',
}

const SEGMENT_TYPE_OPERATORS: Record<
	components['schemas']['FilterType'],
	OPERATOR[]
> = {
	Checkbox: [OPERATOR.IS_IN, OPERATOR.IS_NOT_IN, OPERATOR.CONTAINS],
	List: [OPERATOR.IS_IN, OPERATOR.IS_NOT_IN, OPERATOR.CONTAINS],
	Range: [OPERATOR.IS_LESS_THAN, OPERATOR.IS_GREATER_THAN],
	Date: [OPERATOR.IS_BEFORE, OPERATOR.IS_AFTER],
	ArrayList: [],
	ArrayCheckbox: [],
};

type Nullable<T> = { [K in keyof T]: T[K] | null };
// We don't use Partial<> because if you do and you leave a field undefined, the RHF will
// use a default value to fill in the missing fields.
export type ConditionType = Nullable<components['schemas']['Condition']>;

export type Field = {
	label: string;
	value: string;
	options: { label: string | number; value: string | number }[];
	type: components['schemas']['FilterType'];
};

type SubError = {
	type: string;
	message: string;
};

export type ConditionError = {
	value?: SubError;
	operator?: SubError;
	field?: SubError;
};

export type ConditionErrors = {
	conditions?: ConditionError[];
};

export type ConditionForm = {
	conditions: ConditionType[];
	[key: string]: unknown;
};

export type ConditionProps = {
	setValue: UseFormSetValue<ConditionForm>;
	fields?: Field[];
	errors: ConditionErrors;
	control: Control;
	watch: UseFormWatch<ConditionForm>;
};

const Condition = ({
	fields,
	errors,
	control,
	watch,
}: {
	setValue: UseFormSetValue<ConditionForm>;
	fields?: Field[];
	errors: ConditionErrors;
	control: Control;
	watch: UseFormWatch<ConditionForm>;
}) => {
	const {
		fields: conditions,
		append,
		update,
		remove,
	} = useFieldArray({
		control,
		name: 'conditions',
		keyName: '_id',
	});

	if (fields === undefined) {
		return (
			<ConditionLabel>
				<CircularProgress className="scale-60 my-2" />
			</ConditionLabel>
		);
	}

	if (conditions.length === 0) {
		return (
			<ConditionLabel>
				<Button
					variant="unstyled"
					onClick={() =>
						append({ field: null, operator: null, value: null })
					}
					className={cn(
						'border-1/2 flex items-center gap-4 rounded border-2 border-dashed border-ca-gray-300 bg-ca-gray-100 p-4 shadow-md hover:border-ca-gray-300 hover:bg-ca-gray-200',
						'focus-visible:border-ca-gray-400 focus-visible:bg-ca-gray-300 focus-visible:bg-opacity-10',
					)}
				>
					<PlusIcon className="h-4 w-4" />
					<Text type="secondary">
						No segment(s) added, click{' '}
						<span className="underline">here</span> to create one.
					</Text>
				</Button>
			</ConditionLabel>
		);
	}

	return (
		<ConditionLabel>
			{conditions.map(
				// @ts-ignore TODO: fix type usage in RHF. Unsure why ConditionType is not recognized.
				(condition: ConditionType & { _id: string }, index) => {
					const watchedValue = watch(`conditions.${index}.value`);
					const fieldObject = fields.find(
						({ value }) => condition.field === value,
					);

					const error = errors.conditions?.[index] ?? {};

					return (
						<div
							className="pl-13 relative flex flex-col"
							key={condition._id}
						>
							<div className="flex w-full flex-wrap space-y-2 md:space-x-3 md:space-y-0 xl:w-auto">
								<Controller
									name={`conditions.${index}.field`}
									control={control}
									rules={{
										required: 'Field is required',
									}}
									render={({ field: { value } }) => (
										<Dropdown
											placeholder="Field"
											className="w-full md:w-48 lg:w-64"
											contentClassName="max-h-[254px]"
											value={value}
											onChange={(e) => {
												update(index, {
													field: e,
													operator: null,
													value: null,
												});
											}}
											options={fields}
											error={error?.field?.message}
										/>
									)}
								/>
								{condition.field !== null &&
									fieldObject != null && (
										<Controller
											name={`conditions.${index}.operator`}
											control={control}
											rules={{
												required:
													'Operator is required',
											}}
											render={({ field: { value } }) => (
												<Dropdown
													placeholder="Operator"
													className="w-full md:w-40"
													value={value}
													onChange={(e) => {
														update(index, {
															field: condition.field,
															operator: e,
															value: null,
														});
													}}
													options={SEGMENT_TYPE_OPERATORS[
														fieldObject.type
													].map((o) => ({
														value: o,
														label: o,
													}))}
													error={
														error?.operator?.message
													}
												/>
											)}
										/>
									)}
								{condition.field !== null &&
									condition.operator !== null &&
									fieldObject != null && (
										<Controller
											name={`conditions.${index}.value`}
											control={control}
											rules={{
												required: 'Value is required',
											}}
											render={({
												field: { onChange },
											}) => (
												<ConditionValueInput
													id={condition._id}
													operator={
														condition.operator
													}
													field={fieldObject}
													error={error}
													control={control}
													onChange={onChange}
													value={watchedValue}
												/>
											)}
										/>
									)}
								{index < conditions.length - 1 && (
									<div className="hidden lg:block">
										<div className="rounded-lg bg-ca-silver p-2.5 text-sm font-bold text-ca-gray">
											AND
										</div>
									</div>
								)}
								<div className="flex flex-grow">
									<div className="inline-flex items-center justify-center">
										<IconButton
											icon={TrashIcon}
											tooltip="Remove condition"
											className="h-5"
											onClick={() => {
												remove(index);
											}}
										/>
									</div>
								</div>
							</div>
						</div>
					);
				},
			)}
			{conditions.length > 0 && (
				<div className="md:mr-14 md:w-48 lg:w-64">
					<Button
						variant="unstyled"
						className={cn(
							'w-full rounded-lg border-2 border-dashed border-mi-velvet-lilac p-3 text-center text-sm font-bold text-mi-velvet-lilac transition-colors',
							'focus-visible:border-mi-velvet-lilac-a focus-visible:text-mi-velvet-lilac-a focus-visible:bg-mi-velvet-lilac focus-visible:bg-opacity-10',
							'hover:border-mi-velvet-lilac-a hover:text-mi-velvet-lilac-a hover:bg-mi-velvet-lilac hover:bg-opacity-10',
							'active:text-mi-velvet-lilac-a active:border-mi-velvet-lilac-a active:bg-mi-velvet-lilac active:bg-opacity-10',
						)}
						onClick={(_) =>
							append({ field: null, operator: null, value: null })
						}
					>
						Add another segment
					</Button>
				</div>
			)}
		</ConditionLabel>
	);
};

export default Condition;
