import { GET_PRODUCT_COUNT } from 'api/products';
import Condition, {
	ConditionErrors,
	ConditionForm,
	Field,
} from 'components/Condition/Condition';
import { validateConditions } from 'components/Condition/Condition.utils';
import { LoadingSpinner } from 'crunch-components';
import useChannelQuery from 'hooks/channels/useChannelQuery';
import cloneDeep from 'lodash.clonedeep';
import { useEffect, useState } from 'react';
import {
	Control,
	FieldErrors,
	UseFormSetValue,
	UseFormWatch,
} from 'react-hook-form';
import { components } from '../../../../types/backend-api';
import { FormValues } from '../types/seasons';

type SeasonSettingsScopeProps = {
	fields: Field[];
	isSuccess: boolean; // TODO KILIAN: loading instead?
	setValue: UseFormSetValue<FormValues>;
	control: Control<FormValues, any>;
	watch: UseFormWatch<FormValues>;
	errors: FieldErrors<FormValues>;
};

const SeasonSettingsScope = ({
	fields,
	isSuccess,
	setValue,
	control,
	watch,
	errors,
}: SeasonSettingsScopeProps) => {
	const watchedConditions = watch('conditions');
	// We debounce this input to avoid making too many expensive requests to the backend/BQ.
	// We must clone deep because the watched condition objects are mutated in-place causing incorrect
	// debouncing.
	const fullConditions = cloneDeep(watchedConditions).filter((c) =>
		validateConditions([c]),
	) as components['schemas']['Condition'][];
	const [debouncedConditions, setDebouncedConditions] =
		useState(fullConditions);

	useEffect(() => {
		const timeoutId = setTimeout(() => {
			setDebouncedConditions(fullConditions);
		}, 1500);

		return () => {
			clearTimeout(timeoutId);
		};
	}, [fullConditions]);

	const { isLoading: productCountLoading, data: productCount } =
		useChannelQuery<number, unknown, number>(
			['scoped-product-count', debouncedConditions],
			() => GET_PRODUCT_COUNT(debouncedConditions),
			{
				staleTime: 5 * 60 * 1000,
			},
		);

	// TODO: fix types with generics?
	return (
		<div>
			{!isSuccess && <LoadingSpinner variant="md" />}
			{isSuccess && (
				<>
					<Condition
						errors={errors as ConditionErrors}
						fields={fields}
						control={control as unknown as Control}
						setValue={
							setValue as unknown as UseFormSetValue<ConditionForm>
						}
						watch={watch as unknown as UseFormWatch<ConditionForm>}
					/>
					<p className="text-xs text-ca-black">
						<strong>
							{productCountLoading ? (
								<LoadingSpinner variant="sm" />
							) : (
								productCount
							)}
						</strong>{' '}
						products match the selected conditions
					</p>
				</>
			)}
		</div>
	);
};

export default SeasonSettingsScope;
