import React from 'react';
import { v4 as uuidv4 } from 'uuid';

import dayjs, { Dayjs } from 'dayjs';

import {
	Control,
	Controller,
	FieldErrors,
	useFieldArray,
	UseFieldArrayReturn,
	UseFormGetValues,
	UseFormSetValue,
	UseFormWatch,
} from 'react-hook-form';

import {
	getMondayThatWeek,
	getSelectableDates,
	SelectableDatesArgs,
	today as getToday,
	weekNumber,
} from 'crunch-utils';

import { transformPhases as doPhaseQuerySelect } from './queries';

import {
	Button,
	CalendarIcon,
	cn,
	CornerPinger,
	DeleteButton,
	InfoIcon,
	PlusIcon,
	TextInput,
	Tooltip,
	WarningIcon,
} from 'crunch-components';
import {
	FormValues,
	PhaseGroupType,
	PhaseType,
	SeasonPhasesType,
	SeasonType,
} from '../types/seasons';
import {
	constructEndSeasonFakePhase,
	constructPhasesFromForm,
	constructSeasonFromForm,
	convertPhaseTypeToPhaseDataType,
	transformPhasesData,
} from './SeasonSettings.utils';
import { getPhasesWarnings, getSeasonWarnings } from './strategyWarnings';
import { END_OF_SEASON_PHASE_CONSTANTS, getNewPhaseWeek } from './utils';
import { WeekPicker } from './WeekPicker';

const today = getToday();
const fixedStartDate =
	window._ENV_.REACT_APP_FIXED_T_OF_INTEREST === false
		? today
		: dayjs(window._ENV_.REACT_APP_FIXED_T_OF_INTEREST.toString());

const MAX_SCHEDULED_PHASES = window._ENV_.REACT_APP_MAX_SCHEDULED_PHASES;

type SeasonSettingsPhasesProps = {
	season: SeasonType;
	phases: SeasonPhasesType;
	getValues: UseFormGetValues<FormValues>;
	setValue: UseFormSetValue<FormValues>;
	control: Control<FormValues>;
	errors: FieldErrors<FormValues>;
	watch: UseFormWatch<FormValues>;
};

const COLUMNS = [
	{ id: 'line_num', className: undefined },
	{ id: 'name', className: undefined },
	{ id: 'week', className: undefined },
	{ id: 'start_date', className: undefined },
	{ id: 'actions', className: 'justify-end' },
] as const;

type UpdatePhaseDateLocally = (
	phaseId: PhaseType['id'],
	newDate: PhaseType['start_date'],
) => void;

const cellRenderer = ({
	row,
	columnId,
	lineNum,
	rowIndex,
	control,
	groupName,
	update,
	updateDateLocally,
	remove,
	selectableDates,
	isSeasonEnded,
	errors,
	watch,
}: {
	row: PhaseType;
	columnId: (typeof COLUMNS)[number]['id'];
	lineNum: number;
	rowIndex: number;
	control: Control<any>;
	groupName: PhaseGroupType;
	// TODO finish this
	update:
		| UseFieldArrayReturn<FormValues, 'beforeNow'>['update']
		| UseFieldArrayReturn<FormValues, 'afterNow'>['update'];
	updateDateLocally: UpdatePhaseDateLocally;
	remove: UseFieldArrayReturn['remove'];
	selectableDates: SelectableDatesArgs;
	isSeasonEnded?: boolean;
	errors: FieldErrors;
	watch: UseFormWatch<FormValues>;
}) => {
	const isEndSeason = row.type === 'endseason';
	const isNew = row.type === 'new';
	const fieldPrefix = `${groupName}[${rowIndex}]`;

	if (columnId === 'actions') {
		if (isEndSeason) {
			return null;
		}
		return (
			<span className="relative inline-flex h-[40px] items-center px-2">
				<DeleteButton
					onClick={() => {
						if (isNew) {
							// new items are just deleted
							remove(rowIndex);
							return;
						}
						update(rowIndex, { ...row, type: 'deleted' });
					}}
				/>
			</span>
		);
	}

	if (columnId === 'name') {
		if (!row.hasInput) {
			return (
				<span className="pl-4 text-sm">{String(row[columnId])}</span>
			);
		}

		// TODO
		// @ts-ignore
		const nameError = errors?.[groupName]?.[rowIndex]?.name?.message;

		return (
			<Controller
				name={`${fieldPrefix}.name`}
				control={control}
				rules={{
					required: 'Phase must have a name',
					validate: (currentValue) => {
						const values = watch(['beforeNow', 'afterNow']);
						const isDuplicate = [...values[0], ...values[1]].some(
							(item: PhaseType) =>
								item.name === currentValue &&
								item.id !== row.id &&
								item.type !== 'deleted',
						);
						return isDuplicate ? 'Duplicates not allowed' : true;
					},
				}}
				render={({ field }) => {
					// TODO type this better
					// eslint-disable-next-line
					const { ref, ...fieldProps } = field as any;
					// TextInput requires id
					if (!fieldProps.id) {
						fieldProps.id = `${fieldPrefix}_${rowIndex}_name_input`;
					}
					return (
						<TextInput
							placeholder="Enter name"
							className="w-full"
							{...fieldProps}
							error={nameError}
						/>
					);
				}}
			/>
		);
	}

	if (columnId === 'start_date') {
		return (
			<span className="text-xs text-ca-gray-500">
				{row.startDate.format('D MMM YYYY')}
			</span>
		);
	}

	if (columnId === 'line_num') {
		return isEndSeason ? null : lineNum;
	}

	if (columnId === 'week') {
		const hasWarning = isEndSeason && isSeasonEnded;
		const limits = getSelectableDates({
			...selectableDates,
			allowDate: row.startDate,
		});

		return (
			<WeekPicker
				fixedWeeks
				showOutsideDays
				numberOfMonths={2}
				selectableDates={limits}
				defaultValue={row.startDate.toDate()}
				onSave={(newDate) => {
					if (newDate === undefined) {
						return;
					}

					updateDateLocally(row.id, newDate.format('YYYY-MM-DD'));
				}}
			>
				<Button variant="unstyled" size="small">
					<CornerPinger hidden={!hasWarning} pingColor="red">
						<Tooltip
							content={hasWarning && 'Season has already ended'}
						>
							<div
								className={cn(
									hasWarning
										? 'border-ca-destructive text-ca-destructive'
										: 'border-ca-silver text-ca-black',
									'hover:none flex h-[40px] min-w-[100px] flex-row items-center justify-center gap-3 whitespace-nowrap rounded-lg border bg-white text-xs focus-within:border-ca-purple focus-within:ring-4 focus-within:ring-ca-purple focus-within:ring-opacity-10 focus:outline-none',
								)}
							>
								{`Week ${weekNumber(row.startDate)}`}
								<CalendarIcon
									className={cn(
										'h-3.5 w-auto',
										hasWarning
											? 'text-ca-destructive'
											: 'text-ca-gray-400',
									)}
								/>
							</div>
						</Tooltip>
					</CornerPinger>
				</Button>
			</WeekPicker>
		);
	}

	throw new Error('Unknown columnId');
};

const SeasonSettingsPhases = ({
	phases,
	season,
	getValues,
	setValue,
	control,
	watch,
	errors,
}: SeasonSettingsPhasesProps) => {
	const {
		fields: beforeItems,
		update: updateBeforeItem,
		remove: removeBeforeItem,
	} = useFieldArray({
		control,
		name: 'beforeNow',
		keyName: '_id',
	});
	const {
		fields: afterItems,
		update: updateAfterItem,
		remove: removeAfterItem,
		insert: insertAfterItem,
	} = useFieldArray({
		control,
		name: 'afterNow',
		keyName: '_id',
	});

	const visibleBeforeItems = beforeItems
		.map((item, idx) => ({ row: item, rowIndex: idx }))
		.filter((item) => item.row.type !== 'deleted');
	const visibleAfterItems = afterItems
		.map((item, idx) => ({ row: item, rowIndex: idx }))
		.filter((item) => item.row.type !== 'deleted');

	// one item is endseason, not counting it
	const nextLineNum = visibleBeforeItems.length + visibleAfterItems.length;

	const localPhases = constructPhasesFromForm(getValues);
	const localSeason = constructSeasonFromForm(getValues);
	const localPhasesWithEndOfSeason = {
		beforeNow: localPhases.beforeNow,
		afterNow: [
			...localPhases.afterNow,
			constructEndSeasonFakePhase(localSeason.endDate),
		],
	};

	const phaseWarnings = getPhasesWarnings({
		phases: localPhasesWithEndOfSeason,
		expectEndOfSeason: true,
	});

	const seasonWarnings = getSeasonWarnings(localSeason);

	const selectableBefore = {
		endPeriodDate: today.subtract(1, 'week'),
		selectedDates: visibleBeforeItems.map((item) => item.row.startDate),
	};
	// endSeasonItem.
	const selectableAfter = {
		startPeriodDate: fixedStartDate,
		endPeriodDate:
			visibleAfterItems.find((item) => item.row.type === 'endseason')?.row
				?.startDate ?? season.endDate,
		selectedDates: visibleAfterItems.map((item) => item.row.startDate),
	};

	const lastPhaseDateOrToday = visibleAfterItems
		.filter((item) => item.row.id !== END_OF_SEASON_PHASE_CONSTANTS.id)
		.reduce<Dayjs>(
			(latest, item) => {
				const startDateFollowingMonday = getMondayThatWeek(
					item.row.startDate.add(1, 'weeks'),
				);
				if (startDateFollowingMonday.isAfter(latest)) {
					return startDateFollowingMonday;
				}
				return latest;
			},
			getMondayThatWeek(fixedStartDate.add(1, 'weeks')),
		);
	const selectableEndOfSeason = {
		startPeriodDate: lastPhaseDateOrToday,
	};

	/* FORM UPDATE */
	const updatePhaseDateLocally: UpdatePhaseDateLocally = (
		phaseId,
		newDate,
	) => {
		const currentValues = getValues();

		// 2. all phases as frontend type (not backend type)
		const allPhases: PhaseType[] = [
			...currentValues.afterNow.filter(
				(phase) => phase.type !== END_OF_SEASON_PHASE_CONSTANTS.type,
			),
			...currentValues.beforeNow,
		];

		let seasonEndDate: dayjs.Dayjs | undefined = undefined;
		if (phaseId === END_OF_SEASON_PHASE_CONSTANTS.id) {
			// we are updating the end of season phase
			seasonEndDate = getMondayThatWeek(dayjs(newDate));
		} else {
			const seasonEndPhase = currentValues.afterNow.find(
				(phase) => phase.id === END_OF_SEASON_PHASE_CONSTANTS.id,
			);
			if (seasonEndPhase === undefined) {
				throw new Error(
					`[SeasonSettingsModal::handleUpdatePhaseDateLocally] the end of season phase is not found (impossible).`,
				);
			}
			seasonEndDate = dayjs(seasonEndPhase.startDate);

			// 3. update start date
			const phaseToUpdate = allPhases.find(
				(phaseHay) => phaseHay.id === phaseId,
			);
			if (phaseToUpdate === undefined) {
				throw new Error(
					`[SeasonSettingsModal::handleUpdatePhaseDateLocally] phase not found in all phase (impossible). Value that caused error: phaseId:${phaseId}`,
				);
			}

			// assuming only these two field change
			phaseToUpdate.start_date = getMondayThatWeek(dayjs(newDate)).format(
				'YYYY-MM-DD',
			);
			phaseToUpdate.completed = dayjs(phaseToUpdate.start_date).isBefore(
				getMondayThatWeek(today),
			);
		}

		// 4. undo transformation done in query.select and reapply the transform so that
		// the new phases are exactly as if it was from the database.
		const updatedPhases = doPhaseQuerySelect(
			convertPhaseTypeToPhaseDataType(allPhases),
		);

		// reapply types. Otherwise deleted phases would get reset to 'saved' which is incorrect
		const reapplyTypes = (phase: PhaseType) => {
			const foundType = allPhases.find(({ id }) => id === phase.id)?.type;
			if (!foundType) {
				throw new Error(
					`[SeasonSettingsModal::handleUpdatePhaseDateLocally::reapplyTypes] phase not found in all phase (impossible). Value that caused error: phaseId:${phaseId}`,
				);
			}
			return { ...phase, type: foundType };
		};
		updatedPhases.beforeNow = updatedPhases.beforeNow.map(reapplyTypes);
		updatedPhases.afterNow = updatedPhases.afterNow.map(reapplyTypes);

		// 5. transform phases to form field values
		const beforeNow = transformPhasesData({
			phases,
			seasonEndDate,
			type: 'beforeNow',
		});
		const afterNow = transformPhasesData({
			phases,
			seasonEndDate,
			type: 'afterNow',
		});

		// 6. mark the updatephase so that we can visually show some feedback when a row is updated
		const updatedPhase = [...afterNow, ...beforeNow].find(
			(phase) => phase.id === phaseId,
		);

		if (!updatedPhase) {
			throw new Error(
				`[SeasonSettingsModal::handleUpdatePhaseDateLocally] updated phase not found in all phase (impossible). Error caused by phaseId: ${phaseId}`,
			);
		}
		updatedPhase.doUpdateAnimation = Date.now();

		// 7. update form with new values
		setValue('beforeNow', beforeNow, { shouldDirty: true });
		setValue('afterNow', afterNow, { shouldDirty: true });
	};

	const handleAdd = () => {
		const startDate = getNewPhaseWeek({ phases: localPhases.afterNow });
		insertAfterItem(localPhases.afterNow.length, {
			completed: false,
			name: `Phase ${nextLineNum}`, // TODO: this name can easily be an already existing one. There is frontend FE which will cause errors on save settings. CTRL+F "Phase must have a name"
			startDate,
			start_date: startDate.format('YYYY-MM-DD'),
			type: 'new',
			id: uuidv4(), // note: only for local use until backend has created the new phase & generated its id
			hasInput: true,
		});
	};

	return (
		<>
			<div className="grid grid-cols-[60px_1fr_max-content_max-content_max-content] gap-y-2">
				<h4 className="col-span-5 my-2 text-xs text-ca-gray-500">
					Completed phases
				</h4>
				{visibleBeforeItems.length ? (
					visibleBeforeItems.map(
						({ row, rowIndex }, visibleRowIndex) => {
							return COLUMNS.map(
								({ id: columnId, className: columnStyle }) => (
									<div
										key={
											row.id +
											columnId +
											row.doUpdateAnimation
										}
										className={cn(
											'flex items-center border-b p-2 text-ca-black',
											row.doUpdateAnimation &&
												'animate-background-hit',
											columnStyle,
										)}
									>
										{cellRenderer({
											row,
											columnId,
											rowIndex,
											control,
											lineNum: visibleRowIndex + 1,
											groupName: 'beforeNow',
											update: updateBeforeItem,
											updateDateLocally:
												updatePhaseDateLocally,
											remove: removeBeforeItem,
											selectableDates: selectableBefore,
											isSeasonEnded:
												!!seasonWarnings.warnings
													.seasonEnded,
											errors,
											watch,
										})}
									</div>
								),
							);
						},
					)
				) : (
					<p className="col-span-5 border-b py-3 text-center text-xs text-ca-gray-500">
						No completed phases.
					</p>
				)}
				<div className="col-span-5 my-2 flex items-center">
					<h4 className="text-xs text-ca-gray-500">
						Scheduled phases
					</h4>
					<Tooltip
						content={`Max ${MAX_SCHEDULED_PHASES} scheduled phases`}
					>
						<span className="ml-1.5 inline-block">
							<InfoIcon className="h-3.5 text-ca-gray-500" />
						</span>
					</Tooltip>
				</div>
				{/* TODO extract this component */}
				{phaseWarnings.warnings.noScheduledPhases && (
					<div className="col-span-5 flex flex-col gap-4 rounded-xl border border-dashed border-gray-400 py-3 text-center text-xs text-ca-gray-500">
						<div className="mx-auto flex items-center justify-center gap-2">
							<WarningIcon className="h-4 w-4" />
							No scheduled phases.
						</div>

						<div className="mx-auto flex items-baseline justify-center gap-2">
							You can{' '}
							<Button
								type="button"
								variant="link"
								size="small"
								onClick={handleAdd}
								disabled={
									!!phaseWarnings.warnings.atMaxAllowedPhases
								}
								className="pl-1 pr-1"
							>
								add a phase here
							</Button>{' '}
							or click the &quot;Add phase&quot; button with the
							blinking dot.
						</div>
					</div>
				)}
				{visibleAfterItems.map(({ row, rowIndex }, visibleRowIndex) => {
					return (
						<React.Fragment key={row._id}>
							{COLUMNS.map(
								({ id: columnId, className: columnStyle }) => (
									<div
										key={
											row.id +
											columnId +
											row.doUpdateAnimation
										}
										className={cn(
											'flex flex-row items-center border-b p-2 text-ca-black',
											row.doUpdateAnimation &&
												row.doUpdateAnimation &&
												'animate-background-hit',
											columnStyle,
										)}
									>
										{cellRenderer({
											row,
											columnId,
											rowIndex,
											control,
											lineNum:
												visibleRowIndex +
												visibleBeforeItems.length +
												1,
											groupName: 'afterNow',
											update: updateAfterItem,
											updateDateLocally:
												updatePhaseDateLocally,
											remove: removeAfterItem,
											selectableDates:
												row.id ===
												END_OF_SEASON_PHASE_CONSTANTS.id
													? selectableEndOfSeason
													: selectableAfter,
											isSeasonEnded:
												!!seasonWarnings.warnings
													.seasonEnded,
											errors,
											watch,
										})}
									</div>
								),
							)}
						</React.Fragment>
					);
				})}
			</div>
			<Tooltip
				content={(() => {
					if (phaseWarnings.warnings.atMaxAllowedPhases) {
						return 'You already have the max amount of phases scheduled';
					}
					if (phaseWarnings.warnings.noScheduledPhases) {
						return 'You are not be able to run strategies without a scheduled phase';
					}
					if (phaseWarnings.warnings.noFreeWeekToAddNew) {
						return phaseWarnings.warnings.noFreeWeekToAddNew;
					}
					return undefined;
				})()}
				placement="left"
			>
				<div className="mt-2 flex flex-row">
					<CornerPinger
						hidden={!phaseWarnings.warnings.noScheduledPhases}
					>
						<Button
							variant="primary"
							size="small"
							className="flex flex-row items-center gap-3 whitespace-nowrap"
							onClick={handleAdd}
							disabled={
								!!phaseWarnings.warnings.atMaxAllowedPhases ||
								!!phaseWarnings.warnings.noFreeWeekToAddNew
							}
						>
							<PlusIcon className="h-3.5 w-auto" />
							Add phase
						</Button>
					</CornerPinger>
				</div>
			</Tooltip>
		</>
	);
};

export default SeasonSettingsPhases;
