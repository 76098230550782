import { Transition } from '@headlessui/react';
import { CircularProgress, cn, CrossIcon, Text } from 'crunch-components';
import PropTypes from 'prop-types';
import { Fragment } from 'react';

// Filters labels (~categories) should be in this order (other labels can come after if applicable)
const PRESET_FILTER_LABEL_ORDER = [
	'Product info',
	'Sales info',
	'Recommended markdowns',
	'Expected results',
];

const FilterFieldMenu = ({
	options,
	loading,
	visible,
	popDirection,
	onChange,
	onReset,
	className,
}) => {
	if (!loading && !options.length) return null;

	const othersName = 'Others';

	// TODO WARD: are all these array operations heavyweight enough to consider using useMemo?
	const optionsWithOthers = options.map((option) => {
		const newOption = { ...option };
		if (!option.label) {
			newOption.label = othersName;
		}
		return newOption;
	});

	// Make sure all the presets exist or the result will be very ugly
	const presentPresets = PRESET_FILTER_LABEL_ORDER.filter(
		(i) => optionsWithOthers.find((j) => j.label === i) !== undefined,
	);

	const labels = [
		...new Set([
			// New set does not change the order, so these will be in their corresponding order
			...presentPresets,
			...optionsWithOthers.map((item) => item.label),
		]),
	];

	return (
		<Transition
			appear
			show={visible}
			as={Fragment}
			enter="transition ease-out duration-100"
			enterFrom="transform opacity-0 scale-95"
			enterTo="transform opacity-100 scale-100"
			leave="transition ease-in duration-75"
			leaveFrom="transform opacity-100 scale-100"
			leaveTo="transform opacity-0 scale-95"
		>
			<div
				id="filter-field-menu"
				className={cn(
					'absolute origin-top-left w-64 bg-white rounded-lg shadow-ca focus:outline-none overflow-y-auto transform mt-2 max-h-80 z-30',
					popDirection === 'up' ? 'bottom-full' : '',
					className,
				)}
			>
				{loading && (
					<div className="flex justify-center py-4">
						<CircularProgress />
					</div>
				)}
				{!loading && (
					<>
						{labels.map((label) => (
							<div key={label} className="mb-1">
								{labels.length > 1 && (
									<div className="px-4 py-2 transition-colors decoration-solid font-bold border-b">
										<Text size="text-xs" className="font-bold">
											{label}
										</Text>
									</div>
								)}
								{optionsWithOthers
									.filter((o) => o.label === label)
									.map((o) => (
										<div
											role="button"
											tabIndex={0}
											key={o.id}
											className="px-4 py-2 cursor-pointer transition-colors hover:bg-ca-silver"
											onClick={() => onChange(o.id)}
											onKeyDown={() => onChange(o.id)}
											name={o.id}
										>
											<Text size="text-xs">{o.name}</Text>
										</div>
									))}
							</div>
						))}
						{onReset && (
							<div
								role="button"
								tabIndex={0}
								className="px-4 py-2 cursor-pointer transition-colors hover:bg-ca-silver"
								onClick={onReset}
								onKeyDown={onReset}
							>
								<Text className="font-bold">
									Reset filters
									<CrossIcon className="inline ml-1 h-2.5 text-ca-red-a" />
								</Text>
							</div>
						)}
					</>
				)}
			</div>
		</Transition>
	);
};

FilterFieldMenu.propTypes = {
	options: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.string,
			name: PropTypes.string,
		}),
	),
	loading: PropTypes.bool,
	visible: PropTypes.bool,
	popDirection: PropTypes.oneOf(['up', 'down']),
	onChange: PropTypes.func,
	onReset: PropTypes.func,
	className: PropTypes.string,
};

FilterFieldMenu.defaultProps = {
	options: [],
	loading: false,
	visible: false,
	popDirection: 'down',
	onChange: () => {},
};

export default FilterFieldMenu;
