import { GET_PRODUCT_FILTERS } from 'api/objectives-v2';
import { UPDATE_SEASON_AND_PHASES } from 'api/seasons';
import Title from 'components/Title/Title';
import {
	Button,
	cn,
	CornerPinger,
	CrossIcon,
	IconButton,
	LoadingIcon,
	Modal,
	SaveIcon,
	useModal,
	useToast,
} from 'crunch-components';
import useChannelQuery from 'hooks/channels/useChannelQuery';
import useChannelStore from 'hooks/channels/useChannelStore';
import { useAllLatestRunStatusesQueryKey } from 'hooks/queries/useAllLatestRunStatusesQuery';
import { LIST_BUSINESS_RULES_QUERY_KEY } from 'hooks/queries/useBusinessRulesQuery';
import { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation, useQueryClient } from 'react-query';
import constructChannelQueryKey from 'utils/channelUtils';
import { components } from '../../../../types/backend-api';
import {
	filtersToFields,
	validateConditions,
} from '../../../components/Condition/Condition.utils';
import { ProductFiltersDataType } from '../types/objectives';
import {
	FormValues,
	RowType,
	SeasonPhasesType,
	SeasonType,
	UpdateSeasonAndPhasesPayload,
} from '../types/seasons';
import {
	ALL_PRODUCT_FILTERS_KEY,
	OBJECTIVE_QUERY_KEY,
	SEASON_PHASES_QUERY_KEY,
} from './queries';
import { transformPhasesData, validatePhases } from './SeasonSettings.utils';
import SeasonSettingsCost from './SeasonSettingsCost';
import SeasonSettingsPhases from './SeasonSettingsPhases';
import SeasonSettingsScope from './SeasonSettingsScope';

type SeasonSettingsModalProps = {
	onMutate?: () => Promise<void>;
	onSuccess?: () => Promise<void>;
	phases: SeasonPhasesType;
	season: SeasonType;
};

enum TAB {
	PHASES = 'phases',
	SCOPE = 'scope',
	COSTS = 'costs',
}

const TABS = {
	[TAB.PHASES]: { id: TAB.PHASES, label: 'Phases' },
	[TAB.SCOPE]: { id: TAB.SCOPE, label: 'Products in scope' },
	[TAB.COSTS]: { id: TAB.COSTS, label: 'Costs' },
};

const SeasonSettingsModal = ({
	onMutate,
	onSuccess,
	phases,
	season,
}: SeasonSettingsModalProps) => {
	const [activeTab, setActiveTab] = useState<TAB>(TAB.PHASES);
	const { close } = useModal();
	const { show: showToast } = useToast.getState();

	const initialFormValues: FormValues = {
		beforeNow: transformPhasesData({
			phases,
			seasonEndDate: season.endDate,
			type: 'beforeNow',
		}),
		afterNow: transformPhasesData({
			phases,
			seasonEndDate: season.endDate,
			type: 'afterNow',
		}),
		conditions: season.in_scope_conditions ?? [],
		includeShippingCost: season.include_shipping_cost ?? false,
		includeReturnCost: season.include_return_cost ?? false,
	};

	const {
		control,
		watch,
		handleSubmit: doFormSubmission,
		formState: { isDirty, errors },
		getValues,
		setValue,
		trigger,
	} = useForm<FormValues>({ defaultValues: initialFormValues });

	const productFilterQuery = () =>
		useChannelQuery<
			ProductFiltersDataType,
			unknown,
			ProductFiltersDataType
		>(ALL_PRODUCT_FILTERS_KEY, GET_PRODUCT_FILTERS, {
			staleTime: 30 * 60 * 1000,
		});

	const { data: filterData, isSuccess: filterSuccess } = productFilterQuery();
	const fields = useMemo(() => filtersToFields(filterData), [filterData]);

	const queryClient = useQueryClient();
	const { activeChannel } = useChannelStore();
	const allStatusesQueryKey = useAllLatestRunStatusesQueryKey();
	const { isLoading: isSaveSettingsLoading, mutate: saveSettings } =
		useMutation(UPDATE_SEASON_AND_PHASES, {
			onMutate: async () => {
				await onMutate?.();
			},
			onSuccess: async () => {
				showToast(`Season settings have been saved.`, {
					type: 'success',
				});

				const invalidateKeys = [
					constructChannelQueryKey(
						activeChannel,
						LIST_BUSINESS_RULES_QUERY_KEY, // Business rules include data about phases
					),
					constructChannelQueryKey(
						activeChannel,
						SEASON_PHASES_QUERY_KEY,
					),
					constructChannelQueryKey(activeChannel, [
						'cumulio-scenario-overview',
					]),
					constructChannelQueryKey(
						activeChannel,
						OBJECTIVE_QUERY_KEY,
					),
					allStatusesQueryKey,
				];

				invalidateKeys.forEach((k) => {
					void queryClient.invalidateQueries(k);
				});

				await onSuccess?.();
				close?.();
			},
		});

	const handleSubmit = (data: FormValues) => {
		const allItems = [...data.beforeNow, ...data.afterNow];
		const endOfSeasonItem = allItems.find(
			(item) => item.type === 'endseason',
		);
		if (endOfSeasonItem === undefined) {
			throw new Error(
				'[SeasonSettingsModal::handleSubmit] End of season item not found. Should always be defined.',
			);
		}

		// When form elements are not mounted, their `require`s are not enforced.
		// We double check the validation here and switch tabs if needed.
		const conditionsOK = validateConditions(data.conditions);
		if (!conditionsOK) {
			setActiveTab(TAB.SCOPE);
			void trigger();
			return;
		}

		const phasesOk = validatePhases(data.beforeNow, data.afterNow);
		if (!phasesOk) {
			setActiveTab(TAB.PHASES);
			void trigger();
			return;
		}

		const seasonEndDate: UpdateSeasonAndPhasesPayload['end_date'] =
			endOfSeasonItem.startDate.format('YYYY-MM-DD');

		const VALID_TYPES: RowType[] = ['new', 'saved'];
		const phases = allItems
			.filter((item) => VALID_TYPES.includes(item.type))
			.map((item) => ({
				...(item.type !== 'new' && { id: item.id }),
				name: item.name,
				start_date: item.start_date,
			}));

		saveSettings({
			seasonId: season.id,
			payload: {
				// Cast to NonNullable type because it passed validation.
				in_scope_conditions:
					data.conditions as components['schemas']['Condition'][],
				end_date: seasonEndDate,
				include_shipping_cost: data.includeShippingCost,
				include_return_cost: data.includeReturnCost,
				name: season.name,
				phases,
			},
		});
	};

	const getSaveButtonText = () => {
		if (!filterSuccess) {
			return (
				<>
					<LoadingIcon className="h-3.5 w-auto" />
					<span>Save</span>
				</>
			);
		}
		if (isSaveSettingsLoading) {
			return (
				<>
					<SaveIcon className="h-3.5 w-auto" />
					<span>Saving</span>
				</>
			);
		}
		return (
			<>
				<SaveIcon className="h-3.5 w-auto" />
				<span>Save</span>
			</>
		);
	};

	// TODO: is this the best way to do this?
	let currentContent = null;
	switch (activeTab) {
		case TAB.PHASES:
			currentContent = (
				<SeasonSettingsPhases
					control={control}
					watch={watch}
					errors={errors}
					setValue={setValue}
					getValues={getValues}
					phases={phases}
					season={season}
				/>
			);
			break;
		case TAB.SCOPE:
			currentContent = (
				<SeasonSettingsScope
					fields={fields}
					isSuccess={filterSuccess}
					setValue={setValue}
					control={control}
					watch={watch}
					errors={errors}
				/>
			);
			break;
		case TAB.COSTS:
			currentContent = <SeasonSettingsCost control={control} />;
			break;
		default:
			throw new Error('Invalid tab');
	}

	// TODO KILIAN: product count?
	// TODO KILIAN: it allows you to save if the validation error is on another tab
	return (
		<Modal.Root className="relative w-full max-w-4xl bg-ca-gray-100">
			<span className="absolute right-5 top-5 flex">
				<IconButton
					className="h-auto w-4 text-ca-gray-500"
					tooltip="Close"
					icon={CrossIcon}
					onClick={() => {
						close?.();
					}}
				/>
			</span>
			<div className="px-8 pb-4 pt-8">
				<Modal.Title className="mb-4">
					<Title color="forest" font="serif" as="h2" size="h2-like">
						Season settings
					</Title>
				</Modal.Title>

				<div className="flex w-full gap-10 border-b border-ca-silver py-1">
					{Object.values(TABS).map((t) => (
						<div
							key={t.id}
							onClick={() => setActiveTab(t.id)}
							className={cn(
								'-mb-[5px] cursor-pointer border-b py-2 font-semibold transition-colors hover:text-mi-velvet-lilac',
								t.id === activeTab
									? 'border-b-mi-velvet-lilac text-mi-velvet-lilac'
									: 'border-b-transparent text-mi-neutral',
							)}
						>
							{t.label}
						</div>
					))}
				</div>
			</div>
			<Modal.Content className="p-2 px-8">
				<form
					/* eslint-disable-next-line */
					onSubmit={doFormSubmission(handleSubmit)}
					id="season-settings-save"
					className="flex flex-col"
				>
					{currentContent}
				</form>
			</Modal.Content>
			<Modal.Actions>
				<div className="flex justify-between">
					<Button
						variant="link"
						className="font-medium"
						onClick={() => {
							if (close !== undefined) {
								close();
							}
						}}
					>
						Close
					</Button>
					<CornerPinger hidden={!isDirty || isSaveSettingsLoading}>
						<Button
							className="flex flex-row items-center gap-3 whitespace-nowrap"
							type="submit"
							variant="primary"
							form="season-settings-save"
							disabled={
								!isDirty ||
								isSaveSettingsLoading ||
								!filterSuccess
							}
						>
							{getSaveButtonText()}
						</Button>
					</CornerPinger>
				</div>
			</Modal.Actions>
		</Modal.Root>
	);
};

export default SeasonSettingsModal;
