import { createElement as _createElement } from "react";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Fragment, useRef, useState } from 'react';
import { Transition } from '@headlessui/react';
import useKeyPress from '../../hooks/useKeyPress';
import useOnClickOutside from '../../hooks/useOnClickOutside';
import cn from '../../utils/cn';
import PlusIcon from '../Icons/Plus';
import { search } from 'crunch-utils';
import CheckmarkIcon from '../Icons/Checkmark';
import CrossIcon from '../Icons/Cross';
import TriangleDownIcon from '../Icons/TriangleDown';
const Dropdown = ({ placeholder = 'Select', value = '', options = [], size = 'regular', contentClassName = 'max-h-40', multiple = false, error = false, className = '', onChange = () => { }, onNewButtonClick = () => { }, direction = 'left', dropUpDown = 'down', allowEmpty = false, createNewButton, searchable, }) => {
    const ref = useRef();
    const [isOpen, setIsOpen] = useState(false);
    const [query, setQuery] = useState('');
    useOnClickOutside(ref, () => setIsOpen(false));
    useKeyPress('Escape', () => setIsOpen(false));
    const internalValue = (Array.isArray(value) ? value : [value]).filter((v) => v !== undefined && v !== null);
    const activeOptions = options.filter((option) => internalValue.includes(option.value));
    const handleChange = (val) => {
        if (onChange === undefined) {
            return;
        }
        if (multiple === true) {
            // Remove it from the array (deselect)
            // TODO: fix typing
            //@ts-ignore
            if (internalValue.includes(val)) {
                //@ts-ignore
                onChange(internalValue.filter((v) => v !== val));
            }
            else {
                // Add to the array (select)
                //@ts-ignore
                onChange([...internalValue, val]);
            }
        }
        else {
            //@ts-ignore
            onChange(val);
            setIsOpen(false);
        }
    };
    const clearChoices = (event) => {
        onChange(undefined);
        setIsOpen(false);
        event.stopPropagation();
    };
    const newButtonClick = () => {
        setIsOpen(false);
        onNewButtonClick();
    };
    return (
    //@ts-ignore
    _jsxs("div", { className: cn('inline-block text-left', className), ref: ref, children: [_jsxs("div", { role: "button", tabIndex: 0, onClick: () => setIsOpen(!isOpen), onKeyDown: () => { }, className: cn('relative gap-2 inline-flex justify-between items-center w-full border  bg-white leading-none transition-colors focus:outline-none focus:ring-4 focus:ring-opacity-10', size === 'regular' && 'py-3 px-4 rounded-lg text-sm', size === 'small' && 'py-1.5 px-2 rounded text-xs', {
                    'text-ca-black': !!activeOptions.length && !error,
                    'text-ca-gray': !activeOptions.length && !error,
                }, isOpen && 'border-ca-purple ring-4 ring-opacity-10 ring-ca-purple', !error &&
                    'focus:border-ca-purple focus:ring-ca-purple border-ca-silver', error && 'text-ca-red border-ca-red focus:ring-ca-red'), children: [_jsx("span", { className: cn({
                            'text-ca-black': !!activeOptions.length && !error,
                            'text-ca-gray': !activeOptions.length && !error,
                            'text-ca-red': error,
                        }), children: !activeOptions?.length
                            ? placeholder
                            : activeOptions.map(({ label }) => label).join(', ') }), _jsxs("span", { className: "flex justify-between", children: [allowEmpty && activeOptions?.length ? (_jsx("button", { onClick: clearChoices, type: "button", children: _jsx(CrossIcon, { className: "shrink-0 w-4 text-ca-gray h-2 pointer-events-none" }) })) : null, _jsx(TriangleDownIcon, { className: "shrink-0 text-ca-gray h-2 pointer-events-none" })] }), !!options.length && (_jsx(Transition, { show: isOpen, as: Fragment, enter: "transition ease-out duration-100", enterFrom: "transform opacity-0 scale-95", enterTo: "transform opacity-100 scale-100", leave: "transition ease-in duration-75", leaveFrom: "transform opacity-100 scale-100", leaveTo: "transform opacity-0 scale-95", children: _jsxs("div", { className: cn(`absolute ${direction}-0  min-w-full w-auto py-1 bg-white rounded-lg shadow-ca overflow-y-auto focus:outline-none z-20`, dropUpDown === 'down' && 'origin-top-right mt-2 top-full', dropUpDown === 'up' && 'origin-bottom-right bottom-full mb-2', contentClassName), children: [searchable && (_jsx("input", { className: "text-sm text-black px-4 mt-2 mb-2", placeholder: "Search", onChange: (event) => setQuery(event.target.value) })), options
                                    .filter((option) => {
                                    if (query === '') {
                                        return option;
                                    }
                                    if (search(option.value, query)) {
                                        return option;
                                    }
                                    return null;
                                })
                                    .map((option) => (_createElement("div", { ...(!option?.disabled && {
                                        tabIndex: 0,
                                        role: 'button',
                                        onClick: () => handleChange(option.value),
                                        className: 'cursor-pointer',
                                    }), key: option.value, onKeyDown: () => { } },
                                    _jsxs("div", { className: cn(option?.disabled
                                            ? 'text-ca-gray cursor-not-allowed'
                                            : 'text-ca-black hover:bg-ca-silver', 'flex justify-between items-center px-4 py-2 text-sm transition-colors '), children: [_jsx("span", { children: option.label }), internalValue.includes(option.value) && (_jsx(CheckmarkIcon, { className: "ml-2 h-2 text-ca-purple" }))] })))), createNewButton && (_jsx("div", { tabIndex: 0, role: "button", onClick: newButtonClick, onKeyDown: () => { }, children: _jsxs("div", { className: "flex justify-start gap-2 items-center text-ca-black px-4 py-2 text-sm transition-colors hover:bg-ca-silver", children: [_jsx(PlusIcon, { className: "w-5 inline rounded-full text-white bg-ca-purple ring-1 p-1 ring-ca-purple" }), ' ', _jsx("span", { children: "New" })] }) }, "add_new_scenario"))] }) }))] }), error && typeof error === 'string' && (_jsx("div", { className: "w-full mt-1 pr-1 text-ca-red text-xs text-right", children: error }))] }));
};
export default Dropdown;
